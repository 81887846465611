<template>
  <div style="max-width: 1000px;">
    <p class="h4 mt-5 mb-4">新增消息</p>
    <el-form ref="form" :rules="rules" :model="form">
      <el-form-item prop="title">
        <p class="mb-0 infos-title">标题:</p>
        <el-input type="input" v-model.trim="form.title" placeholder="标题默认加粗" :maxlength="40"></el-input>
      </el-form-item>
      <el-form-item class="quill-style">
        <p class="mb-0 infos-title">文章内容:</p>
        <Editor v-model="editorData" placeholder="再次输入"></Editor>
      </el-form-item>
      <el-form-item prop="needToPush">
        <span class="infos-title">是否进行推送：</span>
        <el-radio-group v-model="form.needToPush">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="mt-5">
        <el-button class="submit-button" size="small" @click="onSubmit('form')">发布</el-button>
        <el-button class="border-button" size="small" @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Editor from '@/components/Editor'
import { postTip } from '@/api/tips'
import { throttle } from '@/lib'

export default {
  name: 'edit',
  components: {
    Editor
  },
  data () {
    return {
      form: {
        title: '',
        needToPush: false
      },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        needToPush: [
          { required: true, message: '请选择是否进行推送', trigger: 'change' }
        ]
      },
      editorData: ''
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    /**
     * 提交验证
     */
    onSubmit: throttle(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const replaceSpace = this.editorData.replace(/\s|\n/g, '').replace(/[<p></p>|<p><br></p>]/g, '')
          if (replaceSpace === '') {
            this.$message.error('请输入文章内容')
            return
          }
          this.setTipInfo()
        } else {
          return false
        }
      })
    }, 500),
    /**
     * 提交的数据
     */
    setJson () {
      const type = this.$route.params.type === 'system' ? 1 : 2
      const json = {
        id: 0,
        ...this.form,
        type
      }
      json.content = this.editorData
      return json
    },
    /**
     * 提交
     */
    setTipInfo () {
      postTip(this.setJson()).then(
        res => {
          this.$notify({
            title: '成功',
            message: res.message,
            type: 'success'
          })
          setTimeout(() => { this.routeTo() }, 1000)
        }
      ).catch(error => {
        console.log(error)
      })
    },
    /**
     * 提交成功跳转
     */
    routeTo () {
      const path = this.$route.params.type
      this.$router.push('/tips-admin/' + path)
    }
  }
}
</script>
<style lang="less" scoped>
.infos-title {
  color: #333333;
  font-weight: 700;
}
</style>
